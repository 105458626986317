import { toggleMoreButton } from "../../bladejs/toggle-more-button";

// Header
// Hamburger Menu Toggle on Mobile
// toggleHamburgerMenu({
// 	button: "#header__hamburger-menu--button",
// 	content: "#header__hamburger-menu--content",
// });

// Footer
// Expand More + button on Mobile
toggleMoreButton({
	button: "#footer__more--button",
	content: "#footer__more--content",
});
