export const toggleMoreButton = ({ button, content }) => {
	const moreButton = document.querySelector(button);
	const moreContent = document.querySelector(content);

	moreButton.addEventListener("click", () => {
		const moreContentOpenState =
			moreContent.dataset.open === "true" ? "false" : "true";

		moreContent.dataset.open = moreContentOpenState;
		moreButton.setAttribute("aria-expanded", moreContentOpenState);
		moreButton.textContent =
			moreContentOpenState === "true" ? "More -" : "More +";
	});
};
